
import React, { Component } from 'react';
import styles from './app.module.css';
import { Button, Image, Input } from 'antd';
import logo1 from '../../resource/img/logo1.jpg';
import logo2 from '../../resource/img/logo2.jpg';
import scan from '../../resource/img/backgrund.png';
import { getUrl } from '../../services/order';

class Fill extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
  }
  render() {
    const { isResult } = this.state;
    return (
      <div className='col-t w100'>
        <div className={`${styles['top']} w100 fz20 bold margin-b10`}>
          欢迎来到诚信智慧码防伪验证系统!
        </div>
        <Image src={logo1} preview={false} className='margin-b10' />
        <div className={`${styles['method']} col vertical-lt w100 margin-b10 fz16 bold`}>
          <div>查询方法：</div>
          <div>1. 刮开标签上的图层，查看防伪码</div>
          <div>2. 在输入框中输入防伪码，点击查询</div>
        </div>
        <div className={`${styles['method']} col vertical-lt w100 margin-b10`}>
          <Input placeholder='请输入您的防伪码 ！' className={`${styles['input']}`} />
          <div className='row w100'>
            <Button onClick={this.toUrl} className={`${styles['btn']} margin-r10`}>查询</Button>
            <Button className={`${styles['btn']}`}>清空</Button>
          </div>
        </div>
        <div className={`${styles['foot']} tc w100 fz16`}>诚信智慧码</div>
      </div>
    );
  }
  toUrl = async () => {
    let res = await getUrl('1234');
    console.log(res);
  }
}
export default Fill;