import request from '../utils/request';

export async function getOrder(id) {
  return await request(`inner/${id}/price/list/v2`, 'get');
}

export async function getCourier(order, companyId, number) {
  return await request(`inner/post/list?order=${order}&company=${companyId}&number=${number}`, 'get');
}

export async function getUrl(code) {
  return await request(`open/weixin/unique?code=${code}`, 'get');
}

