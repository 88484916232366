
import React, { Component } from 'react';
import styles from './app.module.css';
import { Button, Image, Input } from 'antd';
import logo1 from '../../resource/img/logo1.jpg';
import logo2 from '../../resource/img/logo2.jpg';
import scan from '../../resource/img/backgrund.png';

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isResult: false,
    };
  }
  componentDidMount() {

  }
  render() {
    const { isResult } = this.state;
    return (
      <div className='col-t back-color2'>
        <Image src={logo2} preview={false} className='margin-b10' />
        <div className='row w100 margin-b20'>
          <div className={`${styles['tit']} fz17 bold`}>防伪信息</div>
        </div>
        <div className='row-l w100'>
          <div className={`${styles['left']} col-t vertical-lt w100`}>
            <div className='color5'>首次查询时间</div>
            <div className='margin-b10 bold'>2022/06/17 13:45:49</div>
            <div className='color5'>首次查询地区</div>
            <div className='w220 margin-b10 ellipsis-line bold'>浙江省绍兴市绍兴县G104（京岚线）的点点滴少时诵诗书所所所所所所所所所所所轻轻巧巧群滴</div>
            <div className='margin-b10 color5'>查询次数</div>
            <div className='row-l margin-b10'>
              <div className={`${styles['count']} fz18 color1`}>第 8 次</div>
              <div> ☜查看扫码记录</div>
            </div>
            <div className='color6 bold'>（该码已被扫描多次，谨防假冒）</div>
          </div>
          <div className='col-t'>
            <Image src={scan} className='margin-b10' />
            <div className='bold fz16'>点击放大</div>
          </div>
        </div>
      </div>
    );
  }
  search = () => {
    this.setState({
      isResult: true
    });
  }

}
export default Result;